<template>
  <div>
    <Navbar mode="solid" :show-btn-icon="true" :btn-link-path="backPath" :show-currency-picker="false"
      :show-avatar="false" />
    <main class="main">
      <div class="main-container">
        <h1 class="title">{{ $t('page.summary.title') }}</h1>
        <div class="booking-details">
          <div class="destination-block">
            <figure class="agency-img-container">
              <img class="agency-img" :src="agencyImg" alt="Agency agent" />
            </figure>
            <div class="destination-text-container">
              <h4 class="small-title">{{ $t('payment.summary.text_1') }}</h4>
              <div class="destination-flag-container">
                {{ offer.trip_destination }}
              </div>

              <p class="font-sans text-sm text-black-base">
                {{ $t('payment.summary.text_2') }} {{ agent.company_name }}
              </p>
            </div>
          </div>
          <Divider />
          <div class="dates">
            <h3 class="section-title">{{ $t('page.summary.your_trip') }}</h3>
            <div>
              <div class="fulldate-container">
                <div class="date-block">
                  <span class="small-title">
                    {{ $t('payment.summary.start_date') }}
                  </span>
                  <span class="date">{{ startDate }}</span>
                </div>
                <i class="mx-6 text-lg bx bx-arrow-back bx-rotate-180"></i>
                <div class="date-block">
                  <span class="small-title">
                    {{ $t('payment.summary.end_date') }}
                  </span>
                  <span class="date">{{ endDate }}</span>
                </div>
              </div>
              <div class="date-block">
                <span class="small-title">
                  {{ $t('common.travellers') }}
                </span>
                <span class="date">
                  {{ $tc('common.traveller', totalTravellers) }}
                </span>
              </div>
            </div>
          </div>
          <Divider />
          <h3 class="section-title mb-2 mt-6">{{ $t('page.summary.price_details') }}</h3>
          <div class="mt-3">
            <!--ADULTS PRICE-->
            <!--Show if basecurrency equals trip currency-->
            <div v-if="isBase" class="flex justify-between items-center text-black-base">
              <div class="calculation">{{ baseAdultPriceFormatted }} × {{ adults }}</div>
              <div class="result">
                {{ baseTotalAdultPriceFormatted }}
              </div>
            </div>

            <!--Show if basecurrency doesn't equal trip currency-->
            <div v-else class="flex justify-between items-center text-black-base">
              <div class="calculation">{{ adultPriceFormatted }} × {{ adults }}</div>
              <div class="result">
                {{ totalAdultPriceFormatted }}
              </div>
            </div>
            <div v-if="!isBase" class="flex justify-between items-center text-sm text-black-lighter">
              <div class="calculation">{{ baseAdultPriceFormatted }} × {{ adults }}</div>
              <div class="result">
                {{ baseTotalAdultPriceFormatted }}
              </div>
            </div>
          </div>

          <Divider class="w-full my-3" />
          <!--CHILDREN PRICES-->
          <div v-if="offer.trip_children > 0">
            <!--Show if basecurrency equals trip currency-->
            <div v-if="isBase" class="flex justify-between items-center text-black-base">
              <div class="calculation">{{ baseChildPriceFormatted }} × {{ children }}</div>
              <div class="result">
                {{ baseTotalChildPriceFormatted }}
              </div>
            </div>

            <!--Show if basecurrency doesn't equal trip currency-->
            <div v-else class="flex justify-between items-center text-black-base">
              <div class="calculation">{{ childPriceFormatted }} × {{ children }}</div>
              <div class="result">
                {{ totalChildPriceFormatted }}
              </div>
            </div>
            <div v-if="!isBase" class="flex justify-between items-center text-sm text-black-lighter">
              <div class="calculation">{{ baseChildPriceFormatted }} × {{ children }}</div>
              <div class="result">
                {{ baseTotalChildPriceFormatted }}
              </div>
            </div>
          </div>

          <Divider v-if="offer.trip_children > 0" class="w-full my-3" />
          <!--ROOM PRICES-->
          <div v-if="totalSingleRoomPrice > 0">
            <div class="mt-3">
              <!--Show if basecurrency equals trip currency-->
              <div v-if="isBase" class="flex justify-between items-center text-black-base">
                <div class="calculation">{{ baseSingleRoomPriceFormatted }} × {{ singleRooms }}</div>
                <div class="result">
                  {{ baseTotalSingleRoomPriceFormatted }}
                </div>
              </div>

              <!--Show if basecurrency doesn't equal trip currency-->
              <div v-else class="flex justify-between items-center text-black-base">
                <div class="calculation">{{ singleRoomPriceFormatted }} × {{ singleRooms }}</div>
                <div class="result">
                  {{ totalSingleRoomPriceFormatted }}
                </div>
              </div>
              <div v-if="!isBase" class="flex justify-between items-center text-sm text-black-lighter">
                <div class="calculation">{{ baseSingleRoomPriceFormatted }} × {{ singleRooms }}</div>
                <div class="result">
                  {{ baseTotalSingleRoomPriceFormatted }}
                </div>
              </div>
            </div>
          </div>

          <Divider v-if="totalSingleRoomPrice > 0" class="w-full mt-3" />

          <div class="py-3">
            <!--Show if basecurrency equals trip currency-->
            <div v-if="isBase" class="font-bold text-black-base flex justify-between items-center">
              <div class="total">{{ $t("offer.pricing.total") }}</div>
              <div class="result">{{ baseTotalPriceFormatted }}</div>
            </div>
            <!--Show if basecurrency doesn't equal trip currency-->
            <div v-else class="font-bold text-black-base flex justify-between items-center">
              <div class="total">{{ $t("offer.pricing.total") }}</div>
              <div class="result">{{ totalPriceFormatted }}</div>
            </div>
            <div v-if="!isBase" class="font-semibold text-black-lighter text-sm flex justify-between items-center">
              <div class="total">{{ $t("common.totalIn") }} {{ baseCurrency }}</div>
              <div class="result">{{ baseTotalPriceFormatted }}</div>
            </div>
          </div>
        </div>
        <ButtonPrimary @click.native="checkOut" :text="`${$t('payment.button_book')}`" class="btn-submit"
          :loading="btnLoading" />
      </div>

    </main>

    <SubFooter class="subfooter" />

  </div>
</template>

<script>
import dayjs from "dayjs";
import ButtonPrimary from "@/components/buttons/ButtonPrimary";
import Divider from "@/components/dividers/Divider";
import SubFooter from "@/components/layout/SubFooter";
import { mapActions, mapGetters } from "vuex";
import { getStartDate, getEndDate, locale } from "@/shared/dateHandler.js";
import api from "@/api";
import ct from 'countries-and-timezones';
import Navbar from "@/components/layout/Navbar.vue";
export default {
  name: "PaymentSummary",
  components: {
    Navbar,
    ButtonPrimary,
    Divider,
    SubFooter,
  },
  data() {
    return {
      loading: false,
      btnLoading: false,
      bookingDetails: false,
      transactionId: undefined,
    };
  },
  methods: {
    ...mapActions("user", [
      "createPayment",
      "confirmPayment",
      "getSingleTripRequest",
      "getTripAgent",
    ]),
    ...mapActions("exchange", ["setCurrencyRates"]),
    ...mapActions("request", [
      "setThankYouPage",
      "setTripRequestCheckout",
    ]),
    formatToCurrency(value, baseCurrency, quoteCurrency = false, exchangeRate = false) {

      const timezone = ct.getTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
      const country = timezone.countries[0];

      const countryToRegionMap = {
        CH: 'de-CH',
        US: 'en-US',
        DE: 'de-DE',
        FR: 'fr-FR',
        GB: 'en-GB',
        BE: 'fr-BE',
      };

      const region = countryToRegionMap[country] || 'en-US';

      let price;
      const round = (value, precision = 100) => Math.floor(value * precision) / precision;

      // Ensure that currency codes are provided
      if (!baseCurrency) {
        console.warn("Base currency is required with currency style.");
        return value; // Return raw value or a fallback
      }

      if (!quoteCurrency || !exchangeRate || quoteCurrency === baseCurrency) {
        price = new Intl.NumberFormat(region, {
          style: 'currency',
          currency: baseCurrency,  // Ensure baseCurrency is defined
        }).format(value);
      } else {
        const amount = round(value * exchangeRate);
        price = new Intl.NumberFormat(region, {
          style: 'currency',
          currency: quoteCurrency,  // Ensure quoteCurrency is defined
        }).format(amount);
      }

      return price;
    },
    formatToCurrencyValue(value, baseCurrency, quoteCurrency = false, exchangeRate = false) {
      // Rounding function
      const round = (value, precision = 100) => Math.floor(value * precision) / precision;

      // Handle cases where exchange rate or quote currency are not provided
      if (!quoteCurrency || !exchangeRate || quoteCurrency === baseCurrency) {
        return round(value);
      } else {
        // Convert the value with the exchange rate and return the numeric value only
        return round(value * exchangeRate);
      }
    },
    async checkOut() {
      try {
        this.btnLoading = true;
        document.body.style.pointerEvents = "none";

        const res = await api.createCheckoutSession({
          offer_id: this.offer.id,
          trip_total_price: this.totalPrice,
          trip_currency: this.currency.toLowerCase()
        });

        this.setTripRequestCheckout({
          lang: localStorage.getItem("lang") || "en",
          offer_id: this.offer.id,
          checkout_session_id: res.data.checkout_session.id,
          transaction_id: this.offer.transaction_id,
        });


        this.setThankYouPage(true);
        window.location.href = res.data.checkout_session.url;
      } catch (err) {
        console.error('Error during checkout:', err);
      } finally {
        document.body.style.pointerEvents = "";
        this.btnLoading = false;
      }
    },
    async getTrip() {
      try {
        this.loading = true;

        const res = await this.getSingleTripRequest(this.$route.query.trip_id);
        if (!res) return false;

        if (Object.keys(this.offer).length > 0) {
          const agencyId = this.offer.trip_agencies.split(",")[0];
          if (agencyId) {
            await this.getTripAgent(agencyId);
          }
        }
      } catch (error) {
        console.error('Error fetching trip:', error);
      } finally {
        this.loading = false;
      }
    },
    calculateEndTime() {
      const endTime = dayjs(new Date()).format("hh:mm:ss");
      localStorage.setItem("end_book_time", endTime);
    },
    async setBookingEndTime() {
      await this.$store.dispatch("tracker/setBookingEnd");
    },
    async recordBookingDuration() {
      await this.$store.dispatch("tracker/recordBookingDuration");
    },
  },
  computed: {
    ...mapGetters("user", ["offer", "agent", "user"]),
    ...mapGetters("request", ["tripRequestCheckout"]),
    ...mapGetters({ rates: "exchange/rates" }),
    backPath() {
      return `/account/trips/request?id=${this.offer.trip_request_id}`
    },
    agencyImg() {
      return `${process.env.VUE_APP_FILE_BASE_PATH}${this.agent.profile_photo}`;
    },
    isTripDate() {
      return Object.keys(this.offer).length;
    },
    daysInDates() {
      if (this.isTripDate) {
        const date1 = this.offer.trip_dates.split("|")[0].trim();
        const date2 = this.offer.trip_dates.split("|")[1].trim();
        const parseDate1 = dayjs(date1);
        const parseDate2 = dayjs(date2);

        return parseDate2.diff(parseDate1, "days");
      }
      return 0;
    },
    startDate() {
      if (this.isTripDate) {
        return getStartDate(this.offer.trip_dates).locale(locale).format("ddd, DD MMM YYYY");
      }
      return "";
    },
    endDate() {
      if (this.isTripDate) {
        return getEndDate(this.offer.trip_dates).locale(locale).format("ddd, DD MMM YYYY");
      }
      return "";
    },
    baseCurrency() {
      return this.offer.base_trip_currency;
    },
    currency() {
      return this.offer.trip_currency;
    },
    earningFees() {
      return 1 + this.offer.trip_earning_fees / 100;
    },
    totalTravellers() {
      return this.offer.trip_adults + this.offer.trip_children;
    },
    adults() {
      let count = this.offer.trip_adults;
      return this.$tc("offer.pricing.adults", count, {
        count: count,
      });
    },
    children() {
      let count = this.offer.trip_children;
      return this.$tc("offer.pricing.children", count, {
        count: count,
      });
    },
    singleRooms() {
      let count = this.offer.trip_single_rooms;
      return this.$tc("offer.pricing.rooms", count, { count: count });
    },
    rate() {
      return this.rates[`${this.baseCurrency}_${this.currency}`];
    },
    isBase() {
      return this.baseCurrency === this.currency;
    },
    baseAdultPrice() {
      return this.offer.base_trip_price_per_adult * this.earningFees;
    },
    baseAdultPriceFormatted() {
      return this.formatToCurrency(this.baseAdultPrice, this.baseCurrency);
    },
    baseTotalAdultPrice() {
      return this.baseAdultPrice * this.offer.trip_adults;
    },
    baseTotalAdultPriceFormatted() {
      return this.formatToCurrency(this.baseTotalAdultPrice, this.baseCurrency);
    },
    adultPrice() {
      return this.formatToCurrencyValue(this.baseAdultPrice, this.baseCurrency, this.currency, this.rate);
    },
    adultPriceFormatted() {
      return this.formatToCurrency(this.adultPrice, this.currency);
    },
    totalAdultPrice() {
      return this.formatToCurrencyValue(this.adultPrice * this.offer.trip_adults, this.currency);
    },
    totalAdultPriceFormatted() {
      return this.formatToCurrency(this.totalAdultPrice, this.currency);
    },
    baseChildPrice() {
      return this.offer.base_trip_price_per_child * this.earningFees;
    },
    baseChildPriceFormatted() {
      return this.formatToCurrency(this.baseChildPrice, this.baseCurrency);
    },
    baseTotalChildPrice() {
      return this.baseAdultPrice * this.offer.trip_children;
    },
    baseTotalChildPriceFormatted() {
      return this.formatToCurrency(this.baseTotalChildPrice, this.baseCurrency);
    },
    childPrice() {
      return this.formatToCurrencyValue(this.baseChildPrice, this.baseCurrency, this.currency, this.rate);
    },
    childPriceFormatted() {
      return this.formatToCurrency(this.childPrice, this.currency);
    },
    totalChildPrice() {
      return this.formatToCurrencyValue(this.childPrice * this.offer.trip_adults, this.currency);
    },
    totalChildPriceFormatted() {
      return this.formatToCurrency(this.totalChildPrice, this.currency);
    },
    baseSingleRoomPrice() {
      return this.offer.base_trip_single_room_price;
    },
    baseSingleRoomPriceFormatted() {
      return this.formatToCurrency(this.baseSingleRoomPrice, this.baseCurrency);
    },
    baseTotalSingleRoomPrice() {
      return this.baseSingleRoomPrice * this.offer.trip_single_rooms;
    },
    baseTotalSingleRoomPriceFormatted() {
      return this.formatToCurrency(this.baseTotalSingleRoomPrice, this.baseCurrency);
    },
    singleRoomPrice() {
      return this.formatToCurrencyValue(this.baseSingleRoomPrice, this.baseCurrency, this.currency, this.rate);
    },
    singleRoomPriceFormatted() {
      return this.formatToCurrency(this.singleRoomPrice, this.currency);
    },
    totalSingleRoomPrice() {
      return this.formatToCurrencyValue(this.singleRoomPrice * this.offer.trip_single_rooms, this.currency);
    },
    totalSingleRoomPriceFormatted() {
      return this.formatToCurrency(this.totalSingleRoomPrice, this.currency);
    },
    baseTotalPrice() {
      return this.baseTotalAdultPrice + this.baseTotalChildPrice + this.baseTotalSingleRoomPrice;
    },
    baseTotalPriceFormatted() {
      return this.formatToCurrency(this.baseTotalPrice, this.baseCurrency);
    },
    totalPrice() {
      return this.totalAdultPrice + this.totalChildPrice + this.totalSingleRoomPrice;
    },
    totalPriceFormatted() {
      return this.formatToCurrency(this.totalPrice, this.currency);
    },
  },
  beforeDestroy() {
    document.body.classList.remove("overflow-y-hidden");
  },
  async beforeMount() {
    await this.setCurrencyRates();
    if (!Object.keys(this.offer).length || !Object.keys(this.agent).length) {
      await this.getTrip();
      this.transactionId = this.offer.transaction_id;
    }
  },
}
</script>

<style scoped>
.body {
  @apply md:p-20 sm:p-8 p-4 pt-20 md:pt-40 md:pb-0 w-full;
}

.main {
  @apply relative w-full max-w-lg transform -translate-x-1/2 left-1/2;
  @apply pt-20 md:pt-32 px-4;
}

.main-container {
  @apply w-full pb-40;
}

.subfooter {
  @apply relative w-full bg-white max-w-lg transform;
  @apply -translate-x-1/2 left-1/2 xxl:fixed px-4 sm:px-0;
}

.title {
  @apply font-sans text-left font-bold text-black-base text-4xl mb-4;
}

.destination-block {
  @apply py-4 flex items-center;
}

.agency-img-container {
  @apply relative overflow-hidden h-14 w-14 rounded-full mr-3;
}

.agency-img {
  @apply absolute top-0 left-0 w-full h-full object-cover;
}

.dates {
  @apply py-4;
}

.details {
  @apply pb-4;
}

.days-badge {
  @apply h-14 w-14 flex items-center flex-wrap justify-center bg-black-base rounded-xl mr-4;
}

.days-badge-text {
  @apply w-full text-center text-sm;
}

.date-block {
  @apply py-3;
}

.small-title {
  @apply text-black-lightest text-xxs font-bold uppercase block;
}

.destination-flag-container {
  @apply font-sans text-black-base flex items-center;
}

.destination-flag {
  @apply relative overflow-hidden w-6 h-6 mr-2;
}

.flag {
  @apply h-full absolute top-0 left-0;
}

.date {
  @apply font-sans text-black-base text-base;
}

.row {
  @apply flex justify-between py-3;
}

.divider {
  @apply w-full border-b border-grey-base;
}

.booking-details-text {
  @apply flex items-center border-none shadow-none text-xl sm:text-2xl;
}

.btn-submit {
  @apply w-full h-12 mt-8 ml-auto;
}

.fulldate-container {
  @apply flex items-center;
}

.section-title {
  @apply font-bold text-lg text-black-base;
}

.navbar {
  @apply border-none;
  @apply fixed z-40;
}
</style>
